<template>
  <base-section
    id="mission-statement"
    class="px-5"
  >
    <base-section-heading
      title="CORE VALUES"
    >
    </base-section-heading>
      <v-row>
        <v-col
          v-for="(info, n) in information"
          :key="n"
          cols="12"
          md="4"
          sm="12"
        >
          <v-card
            class="mx-auto black--text"
          >
            <v-img
              :src="info.src"
              :height="$vuetify.breakpoint.mdAndUp ? 400 : 200"
            ></v-img>

            <v-card-title>
              {{info.title}}
            </v-card-title>
            <v-card-text
              class="black--text v-space_control"
            >
              {{info.text}}
            </v-card-text>

          </v-card>
        </v-col>
      </v-row>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionMissionStatement',
    data () {
      return {
        information: [
          {
            title: 'HOPE',
            text: 'Contributing value and hope towards a brighter future to the lives of our employees, stakeholders and our customers through our mission.',
            src: require('@/assets/three_pillars_1.jpg'),
          },
          {
            title: 'WELLNESS',
            text: 'Prioritizing on our product quality to safeguard society health, environmental and occupational wellbeing through our business supply chain.',
            src: require('@/assets/three_pillars_2.jpg'),
          },
          {
            title: 'CARING',
            text: 'Being thoughtful to one’s welfare and building trust alongside with our business activities and CSR efforts.',
            src: require('@/assets/three_pillars_3.jpg'),
          },
        ],
      }
    },
  }
</script>
<style type="text/css">
.v-card__text {
  letter-spacing: 0.0rem;
}
</style>
